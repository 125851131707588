/**
 * Based on the asset URL, return which CMS it came from
 */

export const getImageSource = (url: string): string => {
  if (
    url.startsWith("https://images.ctfassets.net/") ||
    url.startsWith("https://videos.ctfassets.net/")
  ) {
    return "contentful";
  } else if (url.startsWith("https://img.redbull.com/")) {
    return "crepo";
  } else if (url.startsWith("https://a.storyblok.com/")) {
    return "storyblok";
  } else {
    return "fallback";
  }
};
